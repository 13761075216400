import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import Section from "../components/Interface/Section"
import { Form, FormGroup, Label, Input } from "reactstrap"

// TODO
// Below userData is an example of possible JSON data returned from graphql query
// But it is NOT being used by the form below (yet)

// const userData = {
//   data: {
//     user: {
//       ACFUserData: {
//         firstName: "Craig",
//         sewingMachine: "Brother Luminaire",
//       },
//     },
//   },
// }

const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = useState( () => {
    if (typeof window !== 'undefined') {
      return localStorage.getItem(localStorageKey) || ""
    }
  })

  useEffect(() => {
    if (typeof window !== 'undefined') {
      localStorage.setItem(localStorageKey, value) // MAYBE REPLACE value WITH ARRAY
    }
  })

  return [value, setValue]
}

const UserForm = () => {
  const [sewingMachine, setSewingMachine] = useStateWithLocalStorage(
    "sewing machine"
  )
  const [name, setName] = useStateWithLocalStorage("user")
  const [zipCode, setZipCode] = useStateWithLocalStorage("zipCode")
  const [distanceFromStore, setDistanceFromStore] = useStateWithLocalStorage(
    "distance from store"
  )
  const [shippingZone, setShippingZone] = useStateWithLocalStorage(
    "shippingZone"
  )

  const onChangeSewingMachine = event => setSewingMachine(event.target.value)
  const onChangeFirstName = event => setName(event.target.value)
  const onChangeZipCode = event => setZipCode(event.target.value)
  const onChangeDistanceFromStore = event =>
    setDistanceFromStore(event.target.value)
  const onChangeShippingZone = event => setShippingZone(event.target.value)

  return (
    <div>
      <div id="userDataForm">
        <h1>User Data with Local Storage!</h1>
        <Form>
          <FormGroup>
            <Label>Sewing Machine</Label>
            <Input
              value={sewingMachine}
              type="text"
              onChange={onChangeSewingMachine}
            />

            <p>Current Machine is {sewingMachine}</p>
          </FormGroup>
          <FormGroup>
            <Label>Name</Label>
            <Input value={name} type="text" onChange={onChangeFirstName} />
            <p>Current Name is {name}</p>
          </FormGroup>
          <FormGroup>
            <Label>Zip Code</Label>
            <Input value={zipCode} type="text" onChange={onChangeZipCode} />
            <p>Current Zip Code is {zipCode}</p>
          </FormGroup>
          <h3>We Won't Show the Next Two: Demonstration Only</h3>
          <FormGroup>
            <Label>Distance from Store</Label>
            <Input
              value={distanceFromStore}
              type="number"
              onChange={onChangeDistanceFromStore}
            />
            <p>Current Distance from Store is {distanceFromStore}</p>
          </FormGroup>
          <FormGroup>
            <Label>Shipping Zone</Label>
            <Input
              value={shippingZone}
              type="number"
              onChange={onChangeShippingZone}
            />
            <p>Current Shipping Zone is {shippingZone}</p>
          </FormGroup>
        </Form>
        <p>
          Since these values will be present in localStorage, we can use them
          throughout the site to pre-fill fields needed for different things.
        </p>
        <p>
          The last 2, and the last 1 in particular, can be used to match the
          shipping zone used by Fedex to determine the rate. Each product will
          have hidden array values that match the shipping amount. The shipping
          module will take the zone key - in this case Zone 2 - and match it to
          the Zone 2 value.
        </p>
      </div>
    </div>
  )
}

const Users = () => (
  <Layout location="users">
    <Section>
      <div>
        <UserForm />
      </div>
    </Section>
  </Layout>
)

export default Users
